<template>
  <div class="explain">
    <img class="demo" src="@/assets/img/explain.jpg" alt="">
    <div class="btns">
      <cube-button class="btn" @click="goBack">服务申请</cube-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'explain',
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    /**
     * 返回页面
     */
    goBack () {
      this.$router.back(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.demo{
  display: block;
  width: 100%;
  min-height: 90vh;
}
.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}
</style>